import { useState } from 'react'
import Logo from "../../../src/assets/logo-2.png";
import { Grid, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import i18n from "../../i18n";
import LanguageIcon from "@mui/icons-material/Language";
import { useTranslation } from 'react-i18next';

const Navbar = () => {
    
  const [anchorEl, setAnchorEl] = useState(null);
  const openn = Boolean(anchorEl);
  const { i18n: lang, t } = useTranslation();
  const translateAra = () => {
      i18n.changeLanguage("ar");
  };
  const translateEng = () => {
      i18n.changeLanguage("en");
  };
    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
      // setOpen(true)
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    // console.log(lang)
  return (
    <div className="nav">
      <Grid container>
        <Grid item md={4}></Grid>
        <Grid
          item
          md={4}
          sm={6}
          xs={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={Logo} width={250} alt='Regards logo' />
        </Grid>
        <Grid
          item
          md={4}
          sm={6}
          xs={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: {
                md: 'center',
                sm: 'flex-end',
                xs: 'flex-end'
            },
          }}
        >
          <Tooltip title="اللغات">
            <IconButton
              size="large"
              aria-label="language"
              sx={{ color: "black" }}
              id="lng-btn"
              onClick={handleClick}
              aria-controls={openn ? "lng-menu" : null}
              aria-haspopup="true"
              aria-expanded={openn ? "true" : undefined}
            >
              <LanguageIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Menu
        MenuListProps={{
          "aria-labelledby": "lng-btn",
        }}
        sx={{ width: "400px" }}
        anchorEl={anchorEl}
        open={openn}
        id="lng-menu"
        onClose={handleClose}
      >
        <MenuItem selected={lang.language === 'ar'} sx={{ width: "100px" }} onClick={() => translateAra()}>
          {t('Ar')}
        </MenuItem>
        <MenuItem selected={lang.language === 'en'} sx={{ width: "100px" }} onClick={() => translateEng()}>
          {t('En')}
        </MenuItem>
      </Menu>
    </div>
  );
}

export default Navbar