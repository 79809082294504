import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  booking_id: "",
  booking_name: "",
  // booking_location: "",
  confirmationDate: "",
  // branch: ''
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    handleName: (state, action) => {
        state.booking_name = action.payload
    },
    handleData: (state, action) => {
        state.confirmationDate = action.payload
    },
    handleLocation: (state, action) => {
        state.booking_location = action.payload
    },
    handleId: (state, action) => {
        state.booking_id = action.payload
    },
    handleBranch: (state, action) => {
        state.branch = action.payload
    },
  },
});

export default formSlice.reducer;
export const { handleData, handleName, handleLocation, handleId, handleBranch } =
  formSlice.actions;