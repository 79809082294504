import { configureStore } from "@reduxjs/toolkit";
import formSlice from "../features/formSlice";


const store = configureStore({
  reducer: {
    form: formSlice
  },
});

export default store;