import { useEffect, useState } from "react";
import "../App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  Box,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Avatar,
} from "@mui/material";
// import Map from './components/map/Map';
import Calender from "../components/calender/Calender";
import Navbar from "../components/navbar/Navbar";
import Banner from "../components/banner/Banner";
import Confirm from "../components/confirm/Confirm";
import Footer from "../components/footer/Footer";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LocationSearch from "../components/locationSearch/LocationSearch";
import Name from "../components/name/Name";
import Success from "../components/success/Success";
import { useParams } from "react-router-dom";
import Loader from "../components/loader/Loader";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { handleId, handleBranch } from "../features/formSlice";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import googleMapImage from "../assets/map.png";

const RootLayout = () => {
  const { t, i18n } = useTranslation();
  const [success, setSuccess] = useState(true);
  const { id } = useParams();
  const data = useSelector((state) => state.form);
  // console.log(data)
  // const navigate = useNavigate()
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [product, setProduct] = useState({});
  const [message, setMessage] = useState({
    status: "",
    message: "",
    showDetail: false,
    linkRef: "",
    link: "",
  });
  const [title, setTitle] = useState(null);

  // console.log(id)

  const formatDate = (date, hoursToAdd = 0) => {
    // Convert the date to a JavaScript Date object
    let d = new Date(date);

    // Add the specified number of hours
    d.setHours(d.getHours() + hoursToAdd);

    // Convert the date to its ISO string representation
    let isoString = d.toISOString();

    // Extract the date and time components
    let year = isoString.slice(0, 4);
    let month = isoString.slice(5, 7);
    let day = isoString.slice(8, 10);
    let hours = isoString.slice(11, 13);
    let minutes = isoString.slice(14, 16);

    // Convert hours to 12-hour format
    let period = "AM";
    if (parseInt(hours, 10) >= 12) {
      period = "PM";
      hours = (parseInt(hours, 10) % 12).toString();
    }

    // Ensure single-digit hours are formatted with a leading zero
    hours = hours.length === 1 ? "0" + hours : hours;

    // Return the formatted date and time in 12-hour format
    return `${year}-${month}-${day} ${hours}:${minutes} ${period}`;
  };

  const getData = async () => {
    try {
      const res = await fetch(
        `https://seashell-app-nua3q.ondigitalocean.app/order/booking/${id}`
      );
      const data = await res.json();
      dispatch(handleId(id));
      if (data.status === "COMPLETED") {
        setMessage({
          status: "success",
          message: "Confirmed booking to view",
          linkRef: "the invitation card",
          // link: `https://invitations.regards.sa/orders/pdf/${data.referenceId}`,
          showDetail: false,
        });
        setLoading(false);
        setSuccess(false);
      }
      if (data.status === "BOOKING_REQUEST") {
        setMessage({
          status: "warn",
          message:
            "Booking request already submitted, will notifed once booking is confirmed",
          showDetail: false,
          link: "",
          linkRef: "",
          date: data.confirmationDate ? "" : "",
        });
        setLoading(false);
        setSuccess(false);
      }
      if (res.status === 404 && data.ar === "لا يوجود طلب لديك") {
        // console.log('first')
        setMessage({
          status: "error",
          message: "You don't have orders",
          showDetail: false,
          link: "",
          linkRef: "",
        });
        setLoading(false);
        setSuccess(false);

        return;
      }

      if (res.status === 200) {
        // console.log(data)
        setLoading(false);
        setProduct(data.product);
        setTitle(data);
        dispatch(handleId(id));
        return;
      }
    } catch (err) {
      setSuccess(false);
      setMessage({
        status: "error",
        message: "Please check your internet connection try again later",
        showDetail: false,
        link: "",
        linkRef: "",
      });
      setLoading(false);
      // toast.error(t('Please check your internet connection try again later'))
    }
  };

  useEffect(() => {
    const lng = localStorage.getItem("i18nextLng");

    i18n.changeLanguage(lng);
    getData();
  }, []);

  useEffect(() => {
    if (
      title?.vendor.service_type === "store" &&
      !data.branch &&
      title?.vendor.branch.length > 0
    ) {
      dispatch(handleBranch(title?.vendor.branch[0].id));
    } else if (
      title?.vendor.service_type === "store" &&
      title?.vendor.branch.length === 0
    ) {
      setMessage({
        status: "error",
        message: "Oops, something went wrong",
        showDetail: false,
        link: "",
        linkRef: "",
      });
      setSuccess(false);
    }
  }, [title, dispatch, data]);

  const handleBranchChange = (branchId) => {
    dispatch(handleBranch(branchId));
  };

  const getSelectedBranchLocation = () => {
    const selectedBranch = title?.vendor.branch.find(
      (branch) => branch.id === data.branch
    );
    return selectedBranch ? selectedBranch.location : "";
  };

  return (
    <HelmetProvider>
      <Helmet>
        <link rel="apple-touch-icon" sizes="180x180" href="/logo.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/logo.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/logo.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta property="og:title" content="Regards" />
        <meta property="og:description" content="Regards Booking form" />
        <meta
          property="og:url"
          content="https://regards-dashboard-five.vercel.app"
        />
        <title>Regards | Booking application</title>
        <meta property="og:type" content="regards | Booking form" />

        <meta property="og:image" content="https://imgur.com/HLT6n2r.png" />
        <meta property="og:image:width" content="450" />
        <meta property="og:image:height" content="135" />
        <meta property="og:image:alt" content="Regards logo" />
        <meta
          property="og:image:secure_url"
          content="https://imgur.com/HLT6n2r.png"
        />
        <meta property="og:image:type" content="image/png" />

        <meta name="twitter:card" content="https://imgur.com/HLT6n2r.png" />
        <meta name="twitter:title" content="Regards" />
        <meta name="twitter:description" content="Regards | Booking form" />
      </Helmet>
      <Stack>
        <Navbar />
      </Stack>
      {!loading && success ? (
        <Stack>
          <Typography
            sx={{ background: "#4c078c", padding: "5px 0", color: "white" }}
          >
            {t("Specific request for order Number")} {title?.id}
          </Typography>
        </Stack>
      ) : null}
      <Toaster position="top-center" />
      {loading ? (
        <Container
          className="body"
          component={"form"}
          sx={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
        >
          <Loader />
        </Container>
      ) : (
        <Container
          className="body"
          component={"form"}
          sx={{ direction: i18n.language === "ar" ? "rtl" : "ltr", mt: 1 }}
        >
          {success ? (
            <>
              <Stack sx={{ mb: 2 }}>
                {product && Object.keys(product).length ? (
                  <Banner product={product} />
                ) : null}
              </Stack>

              <Stack sx={{ mb: 2 }}>
                <Grid container sx={{ gap: "20px" }}>
                  <Grid
                    item
                    md={7}
                    xs={12}
                    sm={12}
                    sx={{
                      boxShadow: "0px 2px 8px -1px rgb(0 0 0 / 15%)",
                      background: "white",
                      borderRadius: "15px",
                      p: 2,
                      alignItems: "start",
                      justifyContent: "start",
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        color={"black"}
                        sx={{
                          fontWeight: 600,
                          fontSize: { md: "16px", sm: "12px", xs: "12px" },
                        }}
                      >
                        {t("Name of gift recipient")}
                      </Typography>
                    </Box>
                    <Name />
                  </Grid>
                </Grid>
              </Stack>
              <Stack sx={{ mb: 2 }}>
                <Grid container sx={{ gap: "20px" }}>
                  <Grid
                    item
                    md={7}
                    xs={12}
                    sm={12}
                    sx={{
                      boxShadow: "0px 2px 8px -1px rgb(0 0 0 / 15%)",
                      background: "white",
                      borderRadius: "15px",
                      p: 2,
                      alignItems: "start",
                      justifyContent: "start",
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        color={"black"}
                        mb={1}
                        sx={{
                          fontWeight: 600,
                          fontSize: { md: "16px", sm: "12px", xs: "12px" },
                        }}
                      >
                        {t("Select date")}
                      </Typography>
                    </Box>
                    <Calender data={title} />
                  </Grid>
                </Grid>
              </Stack>
              {title?.vendor.service_type === "home" ? (
                <Stack sx={{ mb: 2 }}>
                  <Grid container sx={{ gap: "20px" }}>
                    <Grid
                      item
                      md={7}
                      sm={12}
                      xs={12}
                      sx={{
                        background: "white",
                        borderRadius: "15px",
                        p: 2,
                        boxShadow: "0px 2px 8px -1px rgb(0 0 0 / 15%)",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        gap: 5,
                        // height: "",
                      }}
                    >
                      <Box
                        p={1}
                        display={"flex"}
                        flexDirection={"column"}
                        sx={{ width: "100%", alignItems: "flex-start" }}
                      >
                        <Typography
                          variant="h5"
                          color={"black"}
                          mb={2}
                          sx={{
                            fontWeight: 600,
                            fontSize: { md: "16px", sm: "12px", xs: "12px" },
                          }}
                        >
                          {t(
                            "Specify the address (if the gift is a home service)"
                          )}{" "}
                          *
                        </Typography>
                        <LocationSearch />
                        {/* <Map /> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Stack>
              ) : title?.vendor.service_type === "store" ? (
                <Stack sx={{ mb: 2 }}>
                  <Grid container sx={{ gap: "20px" }}>
                    <Grid
                      item
                      md={7}
                      sm={12}
                      xs={12}
                      sx={{
                        background: "white",
                        borderRadius: "15px",
                        p: 2,
                        boxShadow: "0px 2px 8px -1px rgb(0 0 0 / 15%)",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                        gap: 5,
                      }}
                    >
                      <Box
                        p={1}
                        display={"flex"}
                        flexDirection={"column"}
                        sx={{ width: "100%", alignItems: "flex-start" }}
                      >
                        <Typography
                          variant="h5"
                          color={"black"}
                          mb={2}
                          sx={{
                            fontWeight: 600,
                            fontSize: { md: "16px", sm: "12px", xs: "12px" },
                          }}
                        >
                          {t(
                            "Specify the branch (if the gift is in the store)"
                          )}{" "}
                          *
                        </Typography>
                        <Box sx={{ width: "100%" }}>
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={10}>
                              <TextField
                                id="outlined-select-currency"
                                select
                                label={t("branch")}
                                onChange={(e) =>
                                  handleBranchChange(e.target.value)
                                }
                                fullWidth
                                sx={{
                                  mb: 2,
                                  borderRadius: "15px",
                                  direction:
                                    i18n.language === "en" ? "ltr" : "rtl",
                                  "& label": {
                                    transformOrigin:
                                      i18n.language === "en"
                                        ? "left !important"
                                        : "right !important",
                                    right:
                                      i18n.language === "ar"
                                        ? "1.75rem !important"
                                        : "",
                                    left:
                                      i18n.language === "ar"
                                        ? "35.75rem !important"
                                        : "",
                                    fontSize: "small",
                                    color: "#807D7B",
                                    fontWeight: 400,
                                    overflow: "unset",
                                  },
                                  "& legend": {
                                    textAlign:
                                      i18n.language === "en" ? "left" : "right",
                                  },
                                }}
                                defaultValue={title?.vendor.branch[0]?.id}
                              >
                                {title?.vendor.branch.map((option) => (
                                  <MenuItem key={option.id} value={option.id}>
                                    {option[t("branch_en")]}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              sx={{
                                paddingLeft: "0px !important",
                              }}
                            >
                              <IconButton
                                color="primary"
                                sx={{
                                  marginBottom: "10px",
                                  marginTop: "-10px",
                                  marginLeft:
                                    i18n.language === "ar" ? "0px" : "15px",
                                  fontSize: "20px",
                                  "&:hover": {
                                    backgroundColor: "unset",
                                  },
                                }}
                                onClick={() =>
                                  window.open(
                                    getSelectedBranchLocation(),
                                    "_blank"
                                  )
                                }
                              >
                                <img
                                  alt="Google Maps Icon"
                                  src={googleMapImage}
                                  style={{
                                    width: "50px",
                                    height: "auto",
                                    backgroundColor: "transparent",
                                    margin: "auto",
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Stack>
              ) : null}

              <Stack sx={{ mb: 2 }}>
                <Grid container sx={{ gap: "20px" }}>
                  <Grid
                    item
                    md={7}
                    sm={12}
                    xs={12}
                    sx={{
                      background: "white",
                      borderRadius: "15px",
                      p: 2,

                      boxShadow: "0px 2px 8px -1px rgb(0 0 0 / 15%)",
                      alignItems: "start",
                      justifyContent: "start",
                      display: "flex",
                      flexDirection: "column",
                      gap: 5,
                      // height: "",
                    }}
                  >
                    <Confirm
                      serviseType={title?.vendor.service_type}
                      setSuccess={setSuccess}
                      setMessage={setMessage}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </>
          ) : (
            <Stack sx={{ mb: 2 }}>
              <Grid container sx={{ gap: "20px" }}>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    background: "white",
                    borderRadius: "15px",
                    p: 2,

                    boxShadow: "0px 2px 8px -1px rgb(0 0 0 / 15%)",
                    alignItems: "start",
                    justifyContent: "start",
                    display: "flex",
                    flexDirection: "column",
                    gap: 5,
                    // height: "",
                  }}
                >
                  <Success message={message} />
                </Grid>
              </Grid>
            </Stack>
          )}
        </Container>
      )}

      <Stack
        sx={{
          mb: 0,
          mt: 2,
          position: "sticky",
          width: "100%",
          top: "100%",
        }}
      >
        <Footer />
      </Stack>
    </HelmetProvider>
  );
};

export default RootLayout;
