// import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css'
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {ThemeProvider, createTheme } from '@mui/material';
import { Provider } from 'react-redux';
import store from './app/store.js';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration.js";
// import RootLayout from './Layout/RootLayout.jsx';
// import { lime, purple } from '@mui/material/colors';

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://3811d1328917eba6323d7ad625391fbc@o4505273578684416.ingest.sentry.io/4506461836279808",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/booking.regards.sa\/.*$/,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#4c078c",
    },
    warning: {
      main: "#ffeb3b",
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <LocalizationProvider dateAdapter={AdapterDayjs}>
     <Provider store={store}>
      
      <ThemeProvider theme={darkTheme}>
        {/* <RootLayout /> */}
        <App/>
        {/* <div>hi</div> */}
      </ThemeProvider>
     </Provider>
    </LocalizationProvider>
  // </React.StrictMode>
  );
  
serviceWorkerRegistration.register();