import { TextField, debounce } from '@mui/material';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { handleName } from '../../features/formSlice';

const Name = () => {
  const { t, i18n } = useTranslation();
  // const [name, setName] = useState('')
  // const {name} = useSelector(state => state.form)
  const dispatch = useDispatch()

  // console.log(name)
  const debouncedDispatch = debounce((value) => {
    dispatch(handleName(value));
  }, 300);

  const handleChange = (event) => {
    const newValue = event.target.value;
    // console.log(newValue)
    debouncedDispatch(newValue);
  };
  // const handleChange = (event) => {
  //   const newValue = event.target.value;
  //   // setName(newValue)
  //   console.log(newValue)
  //   dispatch(handleName(newValue))
  //   // handleInputChange({ ["name"]: newValue }); // Notify the parent component about the change
  // };
  return (
    <div
      style={{
        width: "100%",
        direction: i18n.language === "ar" ? "rtl" : "ltr",
      }}
    >
      <TextField
        // required
        fullWidth
        id="outlined-required"
        label={t("Booking Name")}
        onChange={handleChange}
        // sx={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
        sx={{
          // m: 1,
          direction: i18n.language === "en" ? "ltr" : "rtl",
          borderRadius: '15px',
          width: "100%",
          "& label": {
            transformOrigin:
              i18n.language === "en" ? "left !important" : "right !important",
            // left: "inherit !important",
            right: i18n.language === "ar" ? "1.75rem !important" : "",
            left: i18n.language === "ar" ? "35.75rem !important" : "",
            fontSize: "small",
            color: "#807D7B",
            fontWeight: 400,
            overflow: "unset",
          },
          "& legend": { textAlign: i18n.language === "en" ? "left" : "right" },
        }}
        // dir='rtl'

        // direction='rtl'
        // defaultValue="Hello World"
      />
    </div>
  );
};

export default Name