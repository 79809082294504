import { Box, Grid, Typography } from "@mui/material";
import { DateCalendar, DigitalClock } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { handleData } from "../../features/formSlice";

const Calender = () => {
  const { t, i18n } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(dayjs().add(24, "hour"));
  const [selectedTime, setSelectedTime] = useState(selectedDate.startOf("day"));
  const [minTime, setMinTime] = useState(null);
  const dispatch = useDispatch();

  // console.log(date)
  const handleDateChange = (newDate) => {
    setSelectedDate(dayjs(newDate));
    console.log(dayjs(newDate).$d);
    dispatch(handleData(newDate.toISOString()));
    const currentDate = dayjs().add(24, "hour");
    const minTime = newDate.isSame(currentDate, "day")
      ? currentDate.add(24, "hour")
      : null;
    setMinTime(minTime);
  };

  useEffect(() => {
    const currentDate = dayjs().add(24, "hour");
    const minTimeValue = selectedDate.isSame(currentDate, "day")
      ? currentDate.add(24, "hour")
      : null;

    // console.log(minTimeValue)
    setMinTime(minTimeValue);
  }, [selectedDate]);

  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
    // console.log(newTime)
    dispatch(handleData(newTime.toISOString()));
  };

  const todayDate = dayjs();
  const minDate = todayDate.add(24, "hour");
  const maxDate = todayDate.add(30, "day");

  useEffect(() => {
    setSelectedTime(selectedDate);
  }, [selectedDate]);

  const shouldDisableAM = (time) => {
    return time.format("a") === "am"; // Disable all AM times
  };

  return (
    <>
      <Box
        // container
        spacing={4}
        sx={{
          alignItems: "flex-start",
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: "column",
          // p: 1,
          gap: 1,
        }}
      >
        <Typography
          variant="p"
          color={"black"}
          sx={{
            fontWeight: 600,
            fontSize: { md: "16px", sm: "12px", xs: "12px" },
            textAlign: "start",
          }}
        >
          {t(
            "We hope you choose the appointment that suits you, and you will be notified for confirmation."
          )}
        </Typography>
      </Box>
      <Grid
        container
        spacing={4}
        direction={"row"}
        display={"flex"}
        sx={{ alignItems: "flex-start", justifyContent: "flex-start" }}
      >
        <Grid
          item
          md={6}
          display={"flex"}
          sx={{ flexDirection: "column" }}
          sm={12}
          xs={12}
        >
          <Typography
            variant="h5"
            textAlign={"start"}
            mb={1}
            sx={{
              fontWeight: 600,
              fontSize: { md: "16px", sm: "12px", xs: "12px" },
            }}
          >
            {t("Day")}
          </Typography>

          <DateCalendar
            label="Date"
            value={selectedDate}
            sx={{
              color: "black",
              backgroundColor: "#f8f8f8",
              width: "100%",
              direction: "ltr",
            }}
            onChange={(newDate) => handleDateChange(newDate)}
            minDate={minDate}
            maxDate={maxDate}
          />
        </Grid>
        <Grid
          item
          md={6}
          display={"flex"}
          sx={{ flexDirection: "column" }}
          sm={12}
          xs={12}
        >
          <Typography
            variant="h5"
            textAlign={"start"}
            mb={1}
            sx={{
              fontWeight: 600,
              fontSize: { md: "16px", sm: "12px", xs: "12px" },
            }}
          >
            {t("Time")}
          </Typography>

          <DigitalClock
            value={selectedTime}
            // timeStep={1}
            sx={{
              color: "black",
              direction: i18n.language === "ar" ? "rtl" : "ltr",
              backgroundColor: "#f8f8f8",
              width: "100%",
            }}
            minTime={minTime}
            onChange={(newTime) => handleTimeChange(newTime)}
            shouldDisableTime={shouldDisableAM}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Calender;
