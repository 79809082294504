import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootLayout from "./Layout/RootLayout";



const router = createBrowserRouter([
  {
    path: "/:id?",
    element: <RootLayout />
  },
]);

// console.log(window.location.pathname.slice(1))


function App() {


  

  return <RouterProvider router={router} />;
}

export default App
