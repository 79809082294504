import { Box, Typography } from '@mui/material';
// import React from 'react'
import { useTranslation } from 'react-i18next';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import TimerIcon from "@mui/icons-material/Timer";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Link } from 'react-router-dom';

const Success = ({message}) => {
  const {t} = useTranslation()
  // console.log(t(message['message']));

  // const throwErr = () => {
  //   throw new Error('error')
  // }
  return (
    <Box
      p={1}
      display={"flex"}
      flexDirection={"column"}
      //onClick={throwErr}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 600,
          fontSize: { md: "16px", sm: "12px", xs: "12px" },
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 1,
        }}
        textAlign={"start"}
      >
        {message?.status === "warn" ? (
          <TimerIcon color="warning" />
        ) : message?.status === "error" ? (
          <ReportGmailerrorredIcon color="error" />
        ) : (
          <DoneAllIcon color="success" />
        )}
        {message.status !== "success" ? (
          <>
          {t(message.message)} {message.date ? message.date : null}
          </>
        ) : (
          <>
            {t(message.message)}{" "}
            {message.link ? (
              <Link
                to={t(message.link)}
                style={{
                  color: "#4c078c",
                  textDecoration: "none",
                  fontWeight: "900",
                }}
              >
                {t(message.linkRef)}
              </Link>
            ) : null}
          </>
        )}
      </Typography>
      <Typography
        variant="p"
        sx={{
          fontWeight: 600,
          fontSize: { md: "16px", sm: "12px", xs: "12px" },
        }}
        textAlign={"start"}
      >
        {message.showDetail
          ? t(
              "Your booking can be canceled with a 100% guaranteed refund if you contact us at least 24 hours prior to your reservation time."
            )
          : null}
      </Typography>
    </Box>
  );
}

export default Success