import React, { useState } from "react";
import { Typography, Modal, Box, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const Banner = ({ product }) => {
  const { i18n } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const description =
    i18n.language === "ar" ? product.overViewAr : product.overViewEn;

  const renderDescription = () => {
    if (!description) return null;
    return description.split("\n").map((line, index) => (
      <Typography
        key={index}
        variant="body1"
        gutterBottom
        sx={{
          fontSize: { md: "16px", sm: "14px", xs: "14px" },
          margin: "20px 7px",
        }}
      >
        {line}
      </Typography>
    ));
  };

  return (
    <div className="banner" key={product.thumbnail}>
      <div className="img">
        <img
          src={product.thumbnail}
          style={{ maxWidth: "150px" }}
          alt={i18n.language === "ar" ? product.nameAr : product.nameEn}
        />
      </div>
      <div className="info">
        <Typography
          variant="h5"
          sx={{
            fontWeight: 600,
            fontSize: { md: "16px", sm: "14px", xs: "14px" },
            margin: "0px 7px",
          }}
        >
          {i18n.language === "ar" ? product.nameAr : product.nameEn}
        </Typography>
        {/* Button to open modal */}
        <Button onClick={handleOpenModal}>
          {i18n.language === "ar" ? "عرض التفاصيل" : "Show Details"}
        </Button>
      </div>

      {/* Modal */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "@media (max-width: 600px)": {
            width: "90%",
            left: "20px",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: "50%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: i18n.language === "ar" ? "right" : "left",
            maxHeight: "80vh",
            overflowY: "auto",
            "@media (max-width: 600px)": {
              width: "90%",
            },
          }}
        >
          {/* Close icon */}
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              left: i18n.language === "ar" ? 8 : "unset",
              right: i18n.language === "ar" ? "unset" : 8,
              top: 8,
              background: "#21033d",
              color: "#fff",
              borderRadius: "6px",
            }}
          >
            <CloseIcon />
          </IconButton>
          {renderDescription()}
        </Box>
      </Modal>
    </div>
  );
};

export default Banner;
