import { Button } from '@mui/material';
import { useState } from 'react'
import LockIcon from "@mui/icons-material/Lock";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

const Confirm = ({ setSuccess, setMessage, serviseType }) => {
  const [checked, setChecked] = useState(true);
  const { t, i18n } = useTranslation();
  const data = useSelector((state) => state.form);
  const [load, setLoad] = useState(false);
  // console.log(data)
  const handleSubmit = async () => {
    setLoad(true);
    console.log(data)
    if (data.booking_location === '' && serviseType === 'home') {
      setLoad(false)
      toast.error(t("Location is required"));
      return;
    }
    if (data.booking_name === '') {
      setLoad(false)
      toast.error(t("Booking name is required"));
      return;
    }
    if (data.confirmationDate === "") {
      setLoad(false);
      toast.error(t("Booking date is required"));
      return;
    }

    try {
      const res = await fetch(
        "https://seashell-app-nua3q.ondigitalocean.app/order/booking-date",
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const ress = await res.json()

      if (!res.ok) {
        setLoad(false);
        throw new Error(ress[t('en')]);
      }

      setSuccess(false);
      setMessage({
        status: "success",
        message: t(
          "Appointment booking request has been successfully received"
        ),
        showDetail: false,
        link: "",
        linkRef: "",
      });
    } catch (err) {
      setLoad(false);
      toast.error(err.message);
      console.log(err.message);
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <Button
        variant="contained"
        startIcon={!checked ? <LockIcon /> : ""}
        sx={{
          mt: 3,
          borderRadius: "50px",
          p: 2,
          width: "100%",
          fontWeight: 600,
          fontSize: { md: "16px", sm: "12px", xs: "12px" },
        }}
        disabled={!checked || load}
        onClick={() => handleSubmit()}
      >
        {t("Booking")}
      </Button>
    </div>
  );
};

export default Confirm