// import React from 'react'
import { Typography } from "@mui/material";
// import Logo from "../../../public/logo.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const {t, i18n} = useTranslation()
  return (
    <div
      className="footer"
      style={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}
    >
      {/* <img src={Logo} width={100} /> */}
      <Typography
        variant="p"
        sx={{
          fontWeight: 600,
          fontSize: { md: "16px", sm: "12px", xs: "12px" },
        }}
      >
        {t("Regards ©2023")}
      </Typography>
    </div>
  );
}

export default Footer